<template>
    <div class="container">
        <header class="jumbotron">
            <h3>Okresy</h3>
        </header>
        <DataTable :value="content" removableSort responsiveLayout="scroll">
            <Column field="year" header="Rok" :sortable="true"></Column>
            <Column field="quarter" header="Kwartał" :sortable="true"></Column>
            <Column headerStyle="width: 14em" bodyStyle="text-align: center; display: inline-block;">
                <template #body="slotProps">
                    <Button type="button" v-show="slotProps.data.id" class="p-button-success" label="Otwórz"
                            v-on:click="openQuarter(slotProps.data.id)"></Button>
                    <Button type="button" v-show="!slotProps.data.id" class="p-button-danger" label="Zamknij"
                            v-on:click="closeQuarter(slotProps.data)"></Button>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>

    import PeroidsService from '../../services/peroids.service';

    export default {
        name: "PeroidsList",
        inject: ['peroids'],
        data() {
            return {
                content: "",
                closedQuarters: null
            };
        },
        mounted() {
            this.content = this.peroids;
            this.getClosedQuarters();
        },
        methods: {
            getClosedQuarters: function () {
                PeroidsService.getClosedQuarters().then(
                    (response) => {
                        this.closedQuarters = response.data;
                        for (const i in this.content) { // :TO DO refactor
                            for (const j in this.closedQuarters) {
                                if (this.content[i].year === this.closedQuarters[j].year && this.content[i].quarter === this.closedQuarters[j].quarter
                                    && (new Date().getFullYear() > new Date(this.closedQuarters[j].validTo).getFullYear() ||
                                        (new Date().getFullYear() === new Date(this.closedQuarters[j].validTo).getFullYear() && new Date().getMonth() >= new Date(this.closedQuarters[j].validTo).getMonth())
                                    )
                                ) {
                                    this.content[i].id = this.closedQuarters[j].id;
                                }
                            }
                        }
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },
            closeQuarter: function (peroid) {
                let quarterToClose = {
                    quarter: peroid.quarter,
                    year: peroid.year
                };
                PeroidsService.closeQuarter(quarterToClose).then(
                    () => {
                        this.getClosedQuarters();
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Zamknięto',
                            detail: 'Poprawnie zamknięto kwartał',
                            life: 3000
                        });
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },
            openQuarter: function (id) {
                PeroidsService.openQuarter(id).then(
                    () => {
                        this.getClosedQuarters();
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Otworzono',
                            detail: 'Poprawnie otworzono kwartał',
                            life: 3000
                        });
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            }
        }
    };
</script>
